import { Route, Routes } from 'react-router-dom';
import { MainLayout } from './components/Layout/main';
import { Rental } from './pages/rental';
import { Login } from './pages/login';
import { Dashboard } from './pages/dasboard';
import { SettingsPage } from './pages/settings';
import { Complaint } from './pages/complaint';

function App() {
    return (
        <MainLayout>
            <Routes>
                <Route path="/" element={<Rental />} />
                <Route path="/beschwerde" element={<Complaint />} />
                <Route path="/login" element={<Login />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/settings" element={<SettingsPage />} />
            </Routes>
        </MainLayout>
    );
}

export default App;
