import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

interface ComplaintFormProps {
    sendComplaint: (data: any) => Promise<void>;
}

export function ComplaintForm({ sendComplaint }: ComplaintFormProps) {
    const formRef = useRef<HTMLFormElement>(null);
    const [recaptcha, setRecaptcha] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);

    async function handleSubmit(event: any) {
        event.preventDefault();
        try {
            setErrorMessage('');
            setSuccessMessage('');
            if (!recaptcha) {
                setErrorMessage('Bitte bestätige, dass du kein Roboter bist');
                return;
            }
            setLoading(true);
            const formData = new FormData(event.target);
            const formValues: any = {};
            formData.forEach((value, key) => (formValues[key] = value));
            console.log(formValues);
            const { message } = formValues;
            if (!message) {
                setErrorMessage('Bitte fülle alle Felder aus.');
                return;
            }

            await sendComplaint(formValues);
            formRef?.current?.reset();
            setSuccessMessage('Deine Beschwerde wurde erfolgreich versendet.');
        } catch (error: unknown) {
            console.error(error);
            setErrorMessage('Es ist ein Fehler aufgetreten.');
        } finally {
            setLoading(false);
        }
    }

    return (
        <form
            ref={formRef}
            onSubmit={handleSubmit}
            className="bg-white shadow-md rounded md:px-8 px-4 pt-6 pb-8 md:m-4 m-1"
        >
            <div>
                <label htmlFor="message">Nachricht*</label>
                <textarea
                    id="message"
                    name="message"
                    rows={4}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>
            <ReCAPTCHA
                className="mt-5"
                sitekey="6LfCYy8lAAAAAOWogJjPr0NYI1JQmqzAYJrfuLma"
                onChange={() => setRecaptcha(true)}
            />
            {errorMessage && (
                <div className="alert alert-error">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current shrink-0 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                    <span>{errorMessage}</span>
                </div>
            )}
            {successMessage && (
                <div className="alert alert-success">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current shrink-0 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                    <span>{successMessage}</span>
                </div>
            )}
            <button
                className="bg-pinte hover:bg-emerald-600 text-white font-bold py-2 px-4 rounded mt-5"
                type="submit"
                disabled={loading}
            >
                {loading ? 'Versende Anfrage...' : 'Abschicken'}
            </button>
        </form>
    );
}
