import axios from 'axios';
import { apiUrl } from '../lib/config';
import { ComplaintForm } from '../components/complaint-form';

export function Complaint() {
    
    async function sendComplaint(data: any) {
        await axios.post(`${apiUrl}complaint`, data);
    }

    return (
        <div className="flex flex-col">
            <div className="bg-white shadow-md rounded md:px-8 px-4 pt-6 pb-8 md:m-4 m-1">
                <h2 className="text-2xl font-bold">In der Pinte ist es zu laut? 🔊</h2>
                <ol className="text-lg list-decimal  m-5 md:m-10">
                    <li>Schreibe dem Heimrat eine <b>anonyme</b> Nachricht 📧</li>
                    <li>Wir werden uns darum kümmern 🚨</li>
                </ol>
            </div>
        
            <ComplaintForm sendComplaint={sendComplaint} />
        </div>
    );
}
